import React, { Component } from 'react';

class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
      langSelected: false,
    };
    this.state.onLangChange = this.props.onLangChange;
  }

  handleLangChange(input) {
    this.state.onLangChange(input);
    this.setState({langSelected: true});
  }

  render() {
    if (this.props.size === 'small') {
      return(
        <div className="miniLanguageSelector">
          <button className="flagButton us-flag" type="submit" onClick={() => this.handleLangChange('en')}><span role="img" aria-label="US flag"></span></button>
          <button className="flagButton se-flag" type="submit" onClick={() => this.handleLangChange('sv')}><span role="img" aria-label="Swedish flag"></span></button>
        </div>
      );
    }

    if (this.props.size === 'big') {
      if (this.state.langSelected === false) {
        return(
          <div>
            <div className="blockoutEverything" />
            <div className="floatingPopup">
              <div className="floatingPopupText">
                <p>
                  <strong>
                    Choose your language
                    <br />
                    Välj ditt språk
                  </strong>
                </p>
                <div>
                  <button className="submitButton languageButton" type="button" onClick={() => this.handleLangChange('en')}>English</button>
                  <button className="submitButton languageButton" type="button" onClick={() => this.handleLangChange('sv')}>Svenska</button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return(
      <div />
    );
  }
}

export { Language };
