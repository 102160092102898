import React, { Component } from 'react';
import './App.css';
import {User} from './User.js'
import {Where} from './Where.js'
import {Hotels} from './Hotels.js'
import {Welcome} from './Welcome.js'
import {Language} from './Language.js'
import {Registry} from './Registry.js'

class App extends Component {

  constructor(props) {
    super(props);
    this.handleLangChange = this.handleLangChange.bind(this);
    this.state = {
      lang: 'en',
    };
  }

  handleLangChange(input) {
    this.setState({lang: input});
  }

  render() {
    return (
      <div className="App">
        <Language size='big' lang={this.state.lang} onLangChange={this.handleLangChange} />
        <Welcome lang={this.state.lang} onLangChange={this.handleLangChange} />
        <User lang={this.state.lang} />
        <Where lang={this.state.lang} />
        <Hotels lang={this.state.lang} />
        <Registry lang={this.state.lang} />
      </div>
    );
  }
}

export default App;
