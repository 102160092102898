import React, { Component } from 'react';

export class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressCallback: this.props.addressCallback,
      addressCaptured: false,
      response: '',
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
      responseToPost: '',
    }

    this.text = {
      line1: '',
      line2: '',
      submit: '',
    };
  }

  componentDidUpdate() {
    this.updateText();
  }

  componentDidMount() {
    this.updateText();
  }

  updateText() {
    if (this.props.lang && this.props.lang === 'en') {
      this.text = {
        addressCallback: this.props.addressCallback,
        addressCaptured: false,
        response: '',
        name: 'name',
        address1: 'address line 1',
        address2: 'address line 2',
        city: 'city',
        state: 'state',
        postcode: 'postcode',
        country: 'country',
        responseToPost: '',
        line1: 'Enter your mailing address below',
        line2: 'Thank you for providing your address! Chris and Elizabeth will update their records. They will have you RSVP at a later date.',
        submit: 'Submit',
      };
    }

    if (this.props.lang && this.props.lang === 'sv') {
      this.text = {
        addressCallback: this.props.addressCallback,
        addressCaptured: false,
        response: '',
        name: 'namn',
        address1: 'addressrad 1',
        address2: 'addressrad 2',
        city: 'stad',
        state: 'län',
        postcode: 'postnummer',
        country: 'land',
        responseToPost: '',
        line1: 'Ange din postadress:',
        line2: 'Tack för att du gav din adress! Chris och Elizabeth kommer uppdatera deras adressbok. Dom kommer ha dig RSVP på ett senare datum.',
        submit: 'Skicka',
      };
    }

    if (this.state.lang !== this.props.lang) {
      this.setState({lang: this.props.lang});
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await fetch('/api/postAddress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.props.email,
          name: this.state.name,
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          postcode: this.state.postcode,
          country: this.state.country
        }),
      });

      // use the server response to set whether the address was captured
      const body = await response.text();
      this.setState({ responseToPost: body });

      if (body) {
        this.setState({ addressCaptured: body });
      }

      if(this.state.addressCallback) {
        this.state.addressCallback(this.state.post);
      }
    } catch (err) {
      console.log("That didn't go according to plan");
    }
  };

  /* Sweden doesn't add state to their post codes*/
  renderCityStateAndPostcode() {
    if (this.props.lang && this.props.lang === 'en') {
      return (
        <div>
          <div>
            <input className="fullWidthInput" type="text" placeholder={this.text.city} name="city" onChange={e => this.setState({ city: e.target.value })} />
          </div>
          <div>
            <input className="leftHalfWidthInput" type="text" placeholder={this.text.state} name="state" onChange={e => this.setState({ state: e.target.value })} />
            <input className="rightHalfWidthInput" type="text" placeholder={this.text.postcode} name="postcode" onChange={e => this.setState({ postcode: e.target.value })} />
          </div>
        </div>
      );
    }
    if (this.props.lang && this.props.lang === 'sv') {
      return (
        <div>
          <input className="leftHalfWidthInput" type="text" placeholder={this.text.city} name="city" onChange={e => this.setState({ city: e.target.value })} />
          <input className="rightHalfWidthInput" type="text" placeholder={this.text.postcode} name="postcode" onChange={e => this.setState({ postcode: e.target.value })} />
        </div>
      );
    }
  }

  render() {
    if (this.state.addressCaptured) {
      return (
        <div className="postcardHalfContents">
          <p>
            {this.text.line2}
          </p>
        </div>
      );
    } else {
      return (
        <div className="postcardHalfContents">
          <form className="postcardForm" onSubmit={this.handleSubmit}>
            <strong>{this.text.line1}</strong>
            <div>
              <input className="fullWidthInput" type="text" placeholder={this.text.name} name="name" onChange={e => this.setState({ name: e.target.value })} />
            </div>
            <div>
              <input className="fullWidthInput" type="text" placeholder={this.text.address1} name="address line 1" onChange={e => this.setState({ address1: e.target.value })} />
            </div>
            <div>
              <input className="fullWidthInput" type="text" placeholder={this.text.address2} name="address line 2" onChange={e => this.setState({ address2: e.target.value })} />
            </div>
            {this.renderCityStateAndPostcode()}
            <div>
              <input className="fullWidthInput" type="text" placeholder={this.text.country} name="postcode" onChange={e => this.setState({ country: e.target.value })} />
            </div>
            <button className="submitButton" type="submit">{this.text.submit}</button>
          </form>
        </div>
      );
    }
  }
}
