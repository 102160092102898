import React, { Component } from 'react';

export class Stamp extends Component {
  render() {
    return(
      <div className="stamp">
        <div className="stampInterior"></div>
      </div>
    );
  }
}
