import React, { Component } from 'react';

export class Where extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
    }
    this.text = {};

    this.updateText();
  }

  componentDidMount() {
    this.updateText();
  }

  componentDidUpdate() {
    this.updateText();
  }

  updateText() {
    if (this.state.lang !== this.props.lang) {
      this.setState({lang: this.props.lang});
    }
    if (this.props.lang === 'en') {
      this.text = {
        line1: 'St James\' Episcopal Church',
        line2: '96 East Genesee Street',
        when1: 'Ceremony: September 1st at 3pm',
        line3: 'Sherwood Inn',
        line4: '26 W Genesee St',
        line5: 'Sunday,',
        line6: 'September 1st,',
        line7: '2019',
        line8: 'Church',
        line9: 'Reception',
        when2: 'Reception: September 1st at 5pm',
      };
    }

    if (this.props.lang === 'sv') {
      this.text = {
        line1: 'St James\' Episcopal Church',
        line2: '96 East Genesee Street',
        when1: 'Ceremoni: 1:e September, kl. 15',
        line3: 'Sherwood Inn',
        line4: '26 W Genesee St',
        line5: 'Söndag,',
        line6: '1:e September,',
        line7: '2019',
        line8: 'Kyrkan',
        line9: 'Bröllopsmiddagen',
        when2: 'Bröllopsmiddagen: 1:e September, kl. 17',
      };
    }
  }

  render() {
    return(
      <div className="widget-like">
        <span className="pseudoPostcard leftPanel">
            <div className="postcardHalfContents church">
              <div className="floatingHeader churchFloatingHeader">
                {this.text.line1}
              </div>
              <div className="floatingHeader2 churchFloatingHeader">
                {this.text.line2}
                <br />
                {this.text.when1}
              </div>
            </div>
        </span>
        <span className="pseudoPostcard rightPanel">
            <div className="postcardHalfContents reception">
              <div className="floatingHeader receptionFloatingHeader">
                {this.text.line3}
              </div>
              <div className="floatingHeader2 receptionFloatingHeader">
                {this.text.line4}
                <br />
                {this.text.when2}
              </div>
            </div>
        </span>
      </div>
    )
  }
}

