import React, { Component } from 'react';

export class Hotels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
    }
    this.text = {};

    this.updateText();
  }

  componentDidMount() {
    this.updateText();
  }

  componentDidUpdate() {
    this.updateText();
  }

  updateText() {
    if (this.state.lang !== this.props.lang) {
      this.setState({lang: this.props.lang});
    }
    if (this.props.lang === 'en') {
      this.text = {
        line1: 'Local Hotels'
        , line2: 'When you call to book at the Sherwood Inn, Mirbeau, or Finger Lakes Lodging, please ask for the Shoenfelt/Troein wedding, since we have reserved hotel blocks at those locations. And there many other boutique hotels and Airbnb’s near the ceremony and reception venues! Please let Jeanne Shoenfelt (Elizabeth\'s mom) know if you need any help finding a room. Her email is sjs91190@gmail.com and her phone number is +1-315-702-3994'
      };
    }

    if (this.props.lang === 'sv') {
      this.text = {
        line1: 'Lokala Hotell'
        , line2: 'När ni ringer för att boka på the Sherwood Inn, Mirbeau, eller Finger Lakes Lodging, fråga järna för "the Shoenfelt/Troein wedding", eftersom dom hotellen här rum sparade för gäster till bröllopet. Och det finns många andra hotell och Airbnb nära ceremonin och receptionen! Vänligen låt Jeanne Shoenfelt (Elizabeths mamma) veta om du behöver hjälp med att hitta ett hotell rum. Hennes email är sjs91190@gmail.com och hennes telefonnummer är +1-315-702-3994'
      };
    }
    this.text.hotels = [
      {
        'name': 'Sherwood Inn'
        , 'price': '~$300-400/night'
        , 'address': '26 West Genesee Street, Skaneateles, NY 13152'
        , 'phone': '+1-315-685-3405'
        , 'phone2': '+1-800-3-SHERWOOD (+1-800-3-74379663)'
      },
      {
        'name': 'Mirbeau'
        , 'price': '~$400/night'
        , 'address': '851 W. Genesee Street, Skaneateles, NY 13152'
        , 'phone': '+1-833-MIRBEAU (+1-833-647-2328)'
        , 'email': 'reservations@mirbeau.com'
      },
      {
        'name': 'Finger Lakes Lodging by Mirbeau'
        , 'price': '~$200/night'
        , 'address': '834 West Genesee Street, Skaneateles, NY 13152'
        , 'phone': '+1-315-217-0222'
      },
      {
        'name': 'Skaneateles Suites Boutique Hotel'
        , 'price': '~$200/night'
        , 'address': '12 Fennel Street, Skaneateles, NY 13152'
        , 'phone': '+1-315-685-7568'
      }
    ];
  };

  renderHotelItem(value) {
    if (value) {
      return(<div key={value} id={value}>{value}</div>);
    }
    return(<div></div>);
  }



  renderHotel(hotel) {
    var arr = [];
    for (var key in hotel) {
      if (key !== 'name') {
        arr.push(hotel[key])
      }
    }
    return(
      <div key={hotel.name} id={hotel.name}>
        <h3>{hotel.name}</h3>
        <div>
          {arr.map(
            (value) => {return(this.renderHotelItem(value))}
          )}
        </div>
      </div>
    )
  }

  render() {
    return(
      <div className="widget smallText" style={{flex:'inline', flexDirection: 'column'}}>
        <h2><u>{this.text.line1}</u></h2>
        {this.text.hotels.map(
          (hotel) => {return(this.renderHotel(hotel))}
        )}
        <p>{this.text.line2}</p>
      </div>
    )
  }
}
