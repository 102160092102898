import React, { Component } from 'react';

export class Registry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: ''
    }
    this.text = {
      line1: '',
    };
  }

  componentDidUpdate() {
    this.updateText();
  }

  componentDidMount() {
    this.updateText();
    var fake = !function(e,t,n) {
      var a=e.getElementsByTagName(t)[0];
      var something = e.getElementById(n);
      if (!something) {
        var s;
        s=e.createElement(t);
        s.id=n;
        s.async=!0;
        s.src="https://widget.zola.com/js/widget.js";
        a.parentNode.insertBefore(s,a);
      };
    }(document,"script","zola-wjs");
  }

  updateText() {
    if (this.props.lang && this.props.lang === 'en') {
      this.text = {
        line1: 'Find our wedding registry at:',
      };
    }

    if (this.props.lang && this.props.lang === 'sv') {
      this.text = {
        line1: 'Find our wedding registry at:',
      };
    }

    if (this.state.lang !== this.props.lang) {
      this.setState({lang: this.props.lang});
    }
  }

  render() {
    return(
      <>
        <a class="zola-registry-embed" href="www.zola.com/registry/troeinfelt" data-registry-key="troeinfelt">Our Zola Wedding Registry</a>
        <div className="widget welcome">
          <div className="inset puppy1">
          </div>
        </div>
      </>
    )
  }
}
