import React, { Component } from 'react';

export class GiveEmail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      post: '',
      defaultPost: '',
      emailCallback: '',
      responseToPost: '',
      errorTriggered: 'false',
      submitTriggered: 'false',
    };
    this.text = {
      line1: '',
      submit: '',
      errorMessage: '',
    };
    this.state.emailCallback = this.props.emailCallback;
  }

  componentDidMount() {
    this.updateText();
  }

  componentDidUpdate() {
    this.updateText();
  }

  updateText() {
    var newPost;
    if (this.props.lang === 'en') {
      newPost = 'user@example.com';

      this.text = {
        line1: 'Enter your email:',
        submit: 'Submit',
        errorMessage: 'Something went wrong. Please contact Chris and Elizabeth!',
      };
    }

    if (this.props.lang === 'sv') {
      newPost = 'användare@exempel.com';

      this.text = {
        line1: 'Ange din e-postadress:',
        submit: 'Skicka',
        errorMessage: 'Något gick fel. Kontacka Chris och Elizabeth!',
      };
    }

    if (this.state.defaultPost !== newPost) {
      this.setState({defaultPost: newPost});
    }
  }

  findUser = async e => {
    e.preventDefault();
    if (this.state.post === this.state.defaultPost) {
      return;
    };

    this.setState({submitTriggered: 'true'});

    try {
      const response = await fetch('/api/findUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({ post: this.state.post }),
      })
      const body = await response.json();
      console.log("Response is: "+JSON.stringify(response));
      if (response.status !== 200) {
        return;
      };
      if(!body || !body.userExists || body.userExists===false) {
        return;
      };

      this.setState({ responseToPost: body.userExists });
      this.setState({submitTriggered: 'false'});
      if(this.state.emailCallback) {
        this.state.emailCallback(this.state.responseToPost, this.state.post);
      }
    } catch (err) {
      this.setState({errorTriggered : "true"});
      console.log('something went wrong');
      console.log(this.text.errorMessage);
      this.setState({submitTriggered: 'false'});
    }
  };

  renderErrorMessage() {
    if (this.state.errorTriggered === "true") {
      return <div>
          {this.text.errorMessage}
        </div>;
    }
    return <div />;
  }

  renderSubmitButton() {
    if (this.state.submitTriggered === 'true') {
      return <button className="submitButton" type="submit">...</button>;
    }
    return <button className="submitButton" type="submit">{this.text.submit}</button>;
  }

  render() {
    return (
      <div className="postcardHalfContents">
        <div>
          <p>{this.state.response}</p>
        </div>
        <div>
          <form onSubmit={this.findUser} className="postcardForm">
            <p>
              <strong>{this.text.line1}</strong>
            </p>
            <input
              type="text"
              placeholder={this.state.defaultPost}
              onChange={
                e => {
                  this.setState({ post: e.target.value.toLowerCase() });
                }
              }
            />
          {this.renderSubmitButton()}
          </form>
        </div>
        {this.renderErrorMessage()}
      </div>
    );
  }
}
