import React, { Component } from 'react';
import {GiveEmail} from './Email.js'
import {Address} from './Address.js'
import {Stamp} from './Stamp.js'
import {RSVP} from './RSVP.js'

export class User extends Component {

  constructor(props) {
    super(props);
    this.state = {
      response: '',
      emailReceived: '',
      emailAddress: '',
      addressCaptured: false,
      responseToPost: '',
    };

    this.updateText();
  }

  updateText() {
    if (this.props.lang === 'en') {
      this.text = {
        line1: 'RSVP!',
        line3: 'Enter your email to let us know if you can make the wedding reception!\nPlease reach out to us at chris.troein@gmail.com and emshoenfelt@gmail.com if you can only make the ceremony or only the reception, or if you have dietary restrictions.',
        line2: 'Sorry! We don\'t recognize this email. Please email Chris and Elizabeth at chris.troein@gmail.com and emshoenfelt@gmail.com directly to RSVP!',
      };
    }

    if (this.props.lang === 'sv') {
      this.text = {
        line1: 'Om svar anhålles!',
        line3: 'Skriv in din email för att meddela om du kan komma till bröllopsmottagningen!\nVänligen kontakta oss på chris.troein@gmail.com and emshoenfelt@gmail.com om du kan komma til bara ceremonin eller mottagningen, eller om du har dietrestriktioner.',
        line2: 'Förlåt! Vi känner inte igen den e-postadressen. Vänligen maila Chris och Elizabeth på chris.troein@gmail.com och emshoenfelt@gmail.com direkt för att RSVP!',
      };
    }
  }
  /*
   *  Check if the user has an email.
   *  If no, ask for email.
   *  If yes, check for address.
   *    If no, ask for address.
   *    If yes, show address.
   */

  emailCallback = ((emailInput, email) => {
    this.setState({ emailReceived: emailInput, emailAddress: email });
  })

  addressCallback = ((addressInput) => {
    this.setState({ addressCaptured: addressInput });
  })

  handleUserInfo() {
    if (this.state.emailReceived === '') { // user email not filled in yet
      return <GiveEmail emailCallback={this.emailCallback} lang={this.props.lang} />;
    }

    if (this.state.emailReceived === true || this.state.emailReceived === 'true') { // user email is filled in
      return <RSVP email={this.state.emailAddress} addressCallback={this.addressCallback} lang={this.props.lang} />;
      // return <Address email={this.state.emailAddress} addressCallback={this.addressCallback} lang={this.props.lang} />;
    }

    // if anything else happens, it must not have been a valid email
    return <div>
        <span className="smallText">{this.text.line2}</span>
        <br />
        <button className="submitButton" type="submit" onClick={() => {this.setState({emailReceived: ''})}}>Try Again</button>
      </div>;
  }

  render() {
    this.updateText();
    return(
      <div className="widget">
        <span className="postcardHalf leftPanel">
          <div className="rotated">
            <div>
              {this.text.line1}
            </div>
            <div className="smallText">
              {this.text.line3}
            </div>
          </div>
        </span>
        <span className="verticalline" />
        <span className="postcardHalf rightPanel">
            {this.handleUserInfo()}
        </span>
      <Stamp />
      </div>
    )
  }
}
