import React, { Component } from 'react';

export class GuestItem extends Component {
  constructor(props) {
    super(props);
    var guest = typeof(this.props.guest) != 'undefined' && this.props.guest.length > 0 ? this.props.guest : "Plus One";
    console.log('this.props.guest is \''+this.props.guest+'\'');
    this.state = {
      guest: guest,
      coming: 'No',
    }
  }

  render() {
    return(
      <>
        <label>
          <input
            type="checkbox"
            className="form-check-input"
            name="coming"
            checked={this.state.coming === "Yes"}
            onChange={
              e => {
                var checked = this.state.coming === 'No' ? 'Yes' : 'No';
                this.setState({ coming: checked });
                console.log("Changed state to "+checked+" for "+this.props.index);
                this.props.callback(this.state.guest, checked, this.props.index)
              }
            }
          />
        </label>
        <label>
          <input
            className="threeQuartersWidthInput"
            type="text"
            placeholder={this.state.guest}
            value={this.state.guest}
            onChange={
              e => {
                var guest = e.target.value
                this.setState({ guest: guest });
                this.props.callback(guest, this.state.coming, this.props.index);
              }
            }
          />
        </label>
        <br />
      </>
    );
  }
}

export class RSVP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rsvpCallback: this.props.rsvpCallback,
      rsvpCaptured: false,
      guestArray: [],
      comingArray: [],
      namesDownloaded: false,
    }
    this.text = {
      line1: '',
      line2: '',
      submit: '',
    };
    this.updateGuest = this.updateGuest.bind(this);
  }

  componentDidUpdate() {
    this.downloadNames();
    this.updateText();
  }

  componentDidMount() {
    this.downloadNames();
    this.updateText();
  }

  updateText() {
    if (this.props.lang && this.props.lang === 'en') {
      this.text = {
        line1: 'Check the checkbox to let us know who is coming!',
        subtitle: 'Names are editable!',
        line2: 'Thank you for your response',
        submit: 'We are coming!',
        submitNone: 'We can\'t come',
      };
    }

    if (this.props.lang && this.props.lang === 'sv') {
      this.text = {
        line1: 'Markera kryssrutan för att meddela vem som kommer!',
        subtitle: 'Namnen är redigerbara!',
        line2: 'Tack!',
        submit: 'Vi kommer!',
        submitNone: 'Vi kan inte komma',
      };
    }

    if (this.state.lang !== this.props.lang) {
      this.setState({lang: this.props.lang});
    }
  }

  downloadNames = async e => {
    if (!this.props.email) {
      return;
    }

    if (this.state.namesDownloaded) {
      return;
    }

    try {
      const response = await fetch('/api/getRSVP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.props.email
        }),
      });
      const body = await response.json();
      var guestArray = [];
      if (body.guest1) { guestArray.push(body.guest1) }
      if (body.guest2) { guestArray.push(body.guest2) }
      var comingArray = [];
      for (var i = 0; i < guestArray.length; i++) {
        comingArray.push('No');
      }
      this.setState({
        namesDownloaded: true,
        guestArray: guestArray,
        comingArray: comingArray,
        hasPlusOne: body.hasPlusOne
      });
      console.log(body);
    } catch (err) {
      console.log("Failed to check who was invited");
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await fetch('/api/postRSVP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.props.email,
          guests: this.state.guestArray,
          coming: this.state.comingArray
        }),
      });

      // use the server response to set whether the address was captured
      const body = await response.text();
      this.setState({ responseToPost: body });

      if (body) {
        this.setState({ rsvpCaptured: body });
      }

      if(this.state.rsvpCallback) {
        this.state.rsvpCallback(this.state.post);
      }
    } catch (err) {
      console.log("That didn't go according to plan");
    }
  };

  handleGuest1Change = changeEvent => {
  };

  addGuest() {
    var guestList = this.state.guestArray;
    var comingList = this.state.comingArray;
    guestList.push('Plus One');
    comingList.push('');
    this.setState({
      guestArray: guestList,
      comingArray: comingList
    });
  }

  renderAddMoreButton() {
    if (!this.state.guestArray || this.state.hasPlusOne < 1) {
      return(<></>);
    }
    if (this.state.guestArray.length > this.state.hasPlusOne) {
      return(<></>);
    }

    return (
      <>
        <button className="submitButton" type="button" onClick={ () => this.addGuest() }>Add Another Guest</button>
        <br />
      </>
    );
  }

  addToGuestArray() {
    var newGuestArray = this.state.guestArray;
    newGuestArray.push('');
    this.setState({guestArray: newGuestArray});
  }

  renderSubmitButton() {
    var anyMatchesValue = function(arr, trueValue) {
      if (!arr) {
        return false;
      };
      for (var i = 0; i<arr.length; i++) {
        if (arr[i]===trueValue) return true;
      }
      return false;
    }
    if (anyMatchesValue(this.state.comingArray, 'Yes')) {
      return (
        <button className="submitButton" type="submit">{this.text.submit}</button>
      );
    } else {
      return (
        <button className="submitButton" type="submit">{this.text.submitNone}</button>
      );
    }
  }

  updateGuest(guest, checked, index) {
    var guestList = this.state.guestArray;
    var comingList = this.state.comingArray;
    if (guestList.length < index) {
      return;
    }
    guestList[index] = guest;
    comingList[index] = checked;
    console.log(guestList, comingList);
    this.setState({
      guestArray: guestList,
      comingArray: comingList
    });
  }

  renderGuestList() {
    if (this.state.guestArray) {
      return(
        <>
          {this.state.guestArray.map( (g, index) => { return(<GuestItem key={index} index={index} guest={g} callback={this.updateGuest} />) })}
          <br />
        </>
      );
    } else {
      return(<></>);
    }
  }

  render() {
    if (this.state.rsvpCaptured) {
      return (
        <div className="postcardHalfContents">
          <p>
            {this.text.line2}
          </p>
        </div>
      );
    } else {
      return (
        <div className="postcardHalfContents">
          <form className="postcardForm" onSubmit={this.handleSubmit}>
            <strong>{this.text.line1}</strong>
            <br />
            <span className="smallText">{this.text.subtitle}</span>
            <br />
            {this.renderGuestList()}
            {this.renderAddMoreButton()}
            {this.renderSubmitButton()}
          </form>
        </div>
      );
    }
  }
}
