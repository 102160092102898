import React, { Component } from 'react';
import {Language} from './Language.js'

class PostcardWelcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
    };
    this.text = {
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      line5: '',
    };
    this.updateText();
  }

  componentDidMount() {
    this.updateText();
  }

  componentDidUpdate() {
    this.updateText();
  }

  updateText() {
    if (this.props.lang === 'en') {
      this.text = {
        line1: 'The wedding of',
        line2: 'Mr. Christopher Henric Troein and',
        line3: 'Dr. Elizabeth Marie Shoenfelt in',
        line4: 'Sunday, September 1st, 2019',
        line5: 'Skaneateles, NY, USA',
      };
    }

    if (this.props.lang === 'sv') {
      this.text = {
        line1: 'Bröllopet av',
        line2: 'Christopher Henric Troein och',
        line3: 'Elizabeth Marie Shoenfelt, PhD i',
        line4: 'Söndag, 1:e September, 2019',
        line5: 'Skaneateles, NY, USA',
      };
    }

    if (this.state.lang !== this.props.lang) {
      this.setState({lang: this.props.lang});
    }
  }

  welcomeTopText() {
    return(
      <div className="welcomeTextTop">
        <span>
          {this.text.line1}
        </span>
        <br />
        <span>
          {this.text.line2}
        </span>
        <br />
        <span>
          {this.text.line3}
        </span>
      </div>
    );
  }

  welcomeBottomText() {
    return(
      <div className="welcomeDate">
        <span>
          {this.text.line4}
        </span>
        <br />
        <span>
          {this.text.line5}
        </span>
      </div>
    );
  }

  render() {
    return(
      <div>
        <div className="rotated">
          {this.welcomeTopText()}
          <div className="SKANEATELES">
            <span className="background-clipping lake2" data-text="SK">
              SK
            </span>
            <span className="background-clipping lake3" data-text="AN">
              AN
            </span>
            <span className="background-clipping lake4" data-text="EA">
              EA
            </span>
            <span className="background-clipping lake5" data-text="TE">
              TE
            </span>
            <span className="background-clipping lake6" data-text="LES">
              LES
            </span>
          </div>
          {this.welcomeBottomText()}
        </div>
      </div>
    )
  }
}

class Welcome extends Component {
  render() {
    return(
    /*
     * You know what would be sick?
     * Using SVG clipping mask a la https://css-tricks.com/clipping-masking-css/
     * With 3D transform
     */
      <div className="widget welcome">
        <div className="inset lake1">
          <Language size='small' onLangChange={this.props.onLangChange} lang={this.props.lang} />
          <div className="postcardHeaderSpacer"/>
          <PostcardWelcome lang={this.props.lang} />
        </div>
      </div>
    )
  }
}

export { Welcome };
export default Welcome;
